<template>
  <div class="login-container">
    <el-form :model="data.myForm" :rules="data.myRule" @keyup.enter="handleSubmit"
             status-icon
             ref="myForm"
             label-position="left"
             label-width="0px"
             class="demo-ruleForm login-page">
      <h3 class="title">注册</h3>
      <el-form-item prop="username">
        <el-input type="text"
                  v-model="data.myForm.username"
                  auto-complete="off"
                  placeholder="用户名"
        />
      </el-form-item>
      <el-form-item prop="email">
        <el-input type="text"
                  v-model="data.myForm.email"
                  auto-complete="off"
                  placeholder="邮箱"
        >
        <template #append>@akuhome.com</template>
        </el-input>
      </el-form-item>
      <el-form-item prop="code">
        <el-input type="text"
                  v-model="data.myForm.code"
                  auto-complete="off"
                  placeholder="验证码"
        >
        <template #append>
          <el-button @click="getCode" :disabled="data.disable.buttonCode" :lodding="data.lodding.code">获取验证码</el-button>
          <span v-show="data.disable.buttonCode" class="count">({{data.codeCount}}s)</span>
        </template>
        </el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input type="password"
                  v-model="data.myForm.password"
                  auto-complete="off"
                  placeholder="密码"
        />
      </el-form-item>
      <el-form-item prop="comPassword">
        <el-input type="password"
                  v-model="data.myForm.comPassword"
                  auto-complete="off"
                  placeholder="确认密码"
        />
      </el-form-item>
      <el-form-item style="width:100%;">
        <el-button type="primary" style="width:100%;" @click="handleSubmit">注册</el-button>
      </el-form-item>
      <el-form-item style="width:100%;">
        <el-button type="success" style="width:100%;" @click="toLogin">去登录</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {api} from "@/api";
import {getCurrentInstance, reactive} from "vue";
import {router} from "@/router";
import {utils} from "@/utils";

export default {
  name: "login",
  setup() {
    const ctx = getCurrentInstance()
    let validatePass = (rule, value, callback) => {
      console.log(1)
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (data.myForm.comPassword !== '') {
          if (data.myForm.comPassword !== value) {
            callback(new Error('两次输入密码不一致'));
            return
          }
        }
        callback();
      }
    }
    let validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== data.myForm.password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    }
    const data = reactive({
      myForm: {
        username: undefined,
        email: undefined,
        code: undefined,
        password: undefined,
        comPassword: undefined,
      },
      disable: {
        buttonCode: false
      },
      lodding: {
        code: false,
      },
      codeCount: 0,
      timer: null,
      myRule: {
        username: [{required: true, message: '请输入账号', trigger: 'blur'}],
        password: [
          {required: true, message: '请输入密码', trigger: 'blur'},
          { min: 6, message: '密码长度大于6个字符', trigger: 'blur' },
          { validator: validatePass, trigger: 'blur' }
        ],
        email: [{required: true, message: '请输入邮箱', trigger: 'blur'}],
        code: [{required: true, message: '请输入验证码', trigger: 'blur'}],
        comPassword: [
          {required: true, message: '请确认密码', trigger: 'blur'},
          { validator: validatePass2, trigger: 'blur' }
        ],
      },
    })
    const methods = {
      toLogin: () => {
        router.push({ path: 'login'})
      },
      getCode: () => {
        data.lodding.code = true
        ctx.refs.myForm.validateField("email", (res) => {
          if (res === ""){
            api.common.captcha(data.myForm.email).then(res => {
              data.lodding.code = false
              if (res.code === 0) {
                utils.message.success("发送成功")
                methods.countdown()
              }else{
                utils.message.error(res.msg)
              }
            })
          }
        })
      },
      countdown: () => {
        let sec = 60;
        if (!data.timer) {
          data.codeCount = sec;
          data.disable.buttonCode = true;
          data.timer = setInterval(() => {
            if (data.codeCount > 0 && data.codeCount <= sec) {
              data.codeCount--;
            } else {
              data.disable.buttonCode = false;
              clearInterval(data.timer); // 清除定时器
              data.timer = null;
            }
          }, 1000)
        }
      },
      handleSubmit: () => {
        console.log(ctx.refs.myForm.validate)
        ctx.refs.myForm.validate((valid) => {
          console.log(456)
          if (valid) {
            api.common.register({
              username: data.myForm.username,
              email: data.myForm.email,
              code: data.myForm.code,
              password: data.myForm.password,
            }).then(res => {
              if (res.code === 0) {
                localStorage.setItem("manage-token", res.data.token);
                router.push({ path: 'project'})
                utils.message.success("注册成功")
              }else {
                utils.message.error(res.msg)
              }
            })
          }else{
            console.log('error submit!');
            return false;
          }
        })
      }
    }
    return {
      data,
      ...methods
    }
  }
};
</script>

<style>
.login-container {
  width: 100%;
  height: 100%;
}
.login-page {
  -webkit-border-radius: 5px;
  border-radius: 5px;
  margin: 180px auto;
  width: 350px;
  padding: 35px 35px 15px;
  background: #fff;
  border: 1px solid #eaeaea;
  box-shadow: 0 0 25px #cac6c6;
}
label.el-checkbox.rememberme {
  margin: 0px 0px 15px;
  text-align: left;
}
</style>
